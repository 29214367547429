














import Vue from 'vue';
import Component from 'vue-class-component';
import JwlButton from '@/components/JwlButton.vue';

@Component({
  components: {
    JwlButton,
  },
})
export default class JwlProgramme extends Vue {
}
